import { axios } from '@/utils/axios'
// import { groupBy } from 'lodash'

// 订单列表
export async function fetchOrderList (params) {
  try {
    return await axios({
      url: '/api/order/page/order',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 订单详情
export async function fetchOrderDetail (params) {
  try {
    return await axios({
      url: '/api/order/detail/Order',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 退票列表查询
export async function fetchRefundList (params) {
  try {
    return await axios({
      url: '/api/order/page/refund/order',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 押金冲抵
export async function depositOffset (params) {
  try {
    return await axios({
      url: '/api/order/payment/offset',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function fetchDepositList (params) {
  try {
    return await axios({
      url: '/api/order/page/deposit/pay',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 押金申退列表查询
export async function fetchDepositRefundList (params) {
  try {
    return await axios({
      url: '/api/order/page/deposit/refund',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 差错退款单列表查询
export async function fetchDifferRefundList (params) {
  try {
    return await axios({
      url: '/api/order/page/mispay',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 差错退款单详情
export async function fetchDifferRefundDetail (params) {
  try {
    return await axios({
      url: '/api/order/detail/mispay',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}
// 押金冲抵列表
export async function fetchDepositOffsetList (params) {
  try {
    return await axios({
      url: '/api/order/page/deposit/offset',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取需求价格信息
export async function fetchFareDetail (params) {
  try {
    return await axios({
      url: `/api/order/segment/fare/details/${params.orderNo}`,
      method: 'post'
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 修改需求价格信息
export async function modifyFareDetail (params) {
  try {
    return await axios({
      url: '/api/order/segment/fare/edit',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取最新政策
export async function fetchPolicy (params) {
  try {
    return await axios({
      url: '/api/order/list/latestPolicy',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 同步最新政策
export async function syncPolicy (params) {
  try {
    return await axios({
      url: '/api/order/sync/order/policy',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 加人
export async function addPeople (params) {
  try {
    return await axios({
      url: '/api/audit/process/ADD_PASSENGER_AUDIT',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取订单库存信息
export async function fetchinVentoryDetail (params) {
  try {
    return await axios({
      url: `/api/order/apply/inventory/details/${params.orderNo}`,
      method: 'get'
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 减人
export async function removePeople (params) {
  try {
    return await axios({
      url: '/api/order/apply/inventory/decrease',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// PNR RT
export async function getPnrRT (params) {
  try {
    return await axios({
      url: '/api/order/pnr/rt',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// dert
export async function getDert (params) {
  try {
    return await axios({
      url: '/api/order/ticket/detr',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 订单分离出票
export async function splitTicketIssue (params) {
  try {
    return await axios({
      url: '/api/order/split/issue',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 修改出票时限
export async function modifyLimitTime (params) {
  try {
    return await axios({
      url: '/api/order/timeLimit/edit',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 查询旅客信息
export async function fetchPassengerDetail (params) {
  try {
    return await axios({
      url: `/api/order/passenger/details/${params.orderNo}`,
      method: 'get'
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 新增旅客信息
export async function addPassengerDetail (params) {
  try {
    return await axios({
      url: '/api/order/passenger/add',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 修改旅客信息
export async function modifyPassengerDetail (params) {
  try {
    return await axios({
      url: '/api/order/passenger/edit',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 删除旅客信息
export async function deletePassengerDetail (params) {
  try {
    return await axios({
      url: '/api/order/passenger/delete',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 提交旅客信息
export async function submitPassengerDetail (params) {
  try {
    return await axios({
      url: '/api/order/passenger/submit',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 查询押金信息详情
export async function fetchDeposit (params) {
  try {
    return await axios({
      url: `/api/order/deposit/details/${params.orderNo}`,
      method: 'post'
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 新增押金期限信息
export async function addDeposit (params) {
  try {
    return await axios({
      url: '/api/order/deposit/add',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 修改押金期限信息
export async function modifyDeposit (params) {
  try {
    return await axios({
      url: '/api/order/deposit/edit',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 修改同音字
export async function modifySimilar (params) {
  try {
    return await axios({
      url: '/api/order/modify/SimPassenger',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 修改证件号
export async function modifyCertNo (params) {
  try {
    return await axios({
      url: '/api/order/modify/passengerId',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 修改需求梳理
export async function modifyCnt (params) {
  try {
    return await axios({
      url: '/api/order/original/group/edit',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 退票单详情
export async function fetchRefundOrderDetail (params) {
  try {
    return await axios({
      url: '/api/order/detail/RefundOrder',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取全部退票信息
export async function fetchRelevanceRefund (params) {
  try {
    return await axios({
      url: `/api/order/list/refund/${params.orderNo}`,
      method: 'get'
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 申退押金单详情
export async function fetchRefundDepositOrderDetail (params) {
  try {
    return await axios({
      url: '/api/order/detail/depositRefund',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 财务退款详情
export async function fetchRefundPaymentOrderDetail (params) {
  try {
    return await axios({
      url: '/api/order/detail/refundPayment',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 在线退款
export async function refundOnline (params) {
  try {
    return await axios({
      url: '/api/refund/online',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 清位
export async function clearPnr (params) {
  try {
    return await axios({
      url: '/api/order/edit/deposit/confirmClearPnr',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 前台接口

// 查询前台押金信息详情
export async function fetchFrontDeposit (params) {
  try {
    return await axios({
      url: `/api/order/deposit/details/${params.orderNo}`,
      method: 'get'
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 查询前台押金信息详情
export async function getDepositRefundFee (params) {
  try {
    return await axios({
      url: '/api/order/queryDepositRefundFee',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 退押金流程接口
export async function depositRefundApplyFlow (params) {
  try {
    return await axios({
      url: '/api/audit/process/DEPOSIT_AUDIT',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 前台机票支付详情
export async function fetchTicketPayDetail (params) {
  try {
    return await axios({
      url: `/api/order/calc/amount/details/${params.orderNo}`,
      method: 'get'
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 前台订单支付
export async function orderPaySubmit (params) {
  try {
    return await axios({
      url: '/api/order/payment/to/pay',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}
// 退票校验
export async function orderRefundCheck (params) {
  try {
    return await axios({
      url: '/api/order/refundVerify',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 退票查询退货费率
export async function orderRefundFee (params) {
  try {
    return await axios({
      url: '/api/order/queryRefundFee',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 退票
export async function orderRefundApply (params) {
  try {
    return await axios({
      url: '/api/order/refundApply',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 退票流程接口
export async function orderRefundApplyFlow (params) {
  try {
    return await axios({
      url: '/api/audit/process/REFUND_AUDIT',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 一键出票接口
export async function orderCheckout (params) {
  try {
    return await axios({
      url: '/api/order/auto/issue',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 差错退款申请
export async function applyMispay (params) {
  try {
    return await axios({
      url: '/api/audit/process/MISPAY_AUDIT',
      method: 'post',
      data: params
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 差错退款支付记录查询
export async function getMispayInfo (params) {
  try {
    return await axios({
      url: `/api/mispay/misPayInfo/${params.orderNo}`,
      method: 'get'
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 差错退款支付状态查询
export async function queryMispayPayStatus (params) {
  try {
    return await axios({
      url: `/api/mispay/query/payStatus/${params.tradeNo}`,
      method: 'get'
    })
  } catch (err) {
    return Promise.reject(err)
  }
}
